import 'react-app-polyfill/ie11';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';
import { Redirect, Router } from "react-router";
import { createBrowserHistory } from "history";
import * as serviceWorker from './serviceWorker';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const rootElement = document.getElementById('root');
const history = createBrowserHistory();
const RedirectToExternal = () => {
  useEffect(() => {
    // Redirect to the external website
    window.location.href = 'https://research.natixis.com/Site/en';
  }, []);

  return null; // Optionally, you can return a loading indicator or a message here
};

ReactDOM.render(
  <Router history={history}>
        {/* <App />*/}
        <RedirectToExternal />
  </Router >,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();